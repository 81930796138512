import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid";

import { ExploreMore } from "../Section"
import { ArrowRightIcon } from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"
import ArticleCard from "../ArticleCard"
import BreakpointUp from "../Media/BreakpointUp"

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.xl`
    margin-right: -20px;
    margin-left: -20px;
  `}
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 33.333%;
    max-width: 33.333%;
  `}
  ${BreakpointUp.xl`
    padding-right: 20px;
    padding-left: 20px;
  `}
`
const ArticleCardList = ({ intl, data }) => {
  return (
    <div>   
        <SectionGrid>
          {
            data.map((edge, i)=>{
              if(i< 3) {
                return (
                  <SectionItem key={v4()}><ArticleCard data={edge.node} intl={intl} /></SectionItem>
                )
              }
              return true;
            })
          }
        </SectionGrid>
        <ExploreMore>
            <Link to={`/${intl.locale}/blog`} className="btn-lg">
              <PrimaryButton icon={<ArrowRightIcon />} text={intl.formatMessage({ id: "View All Articles"})} />                    
            </Link>
        </ExploreMore>
    </div>
  )
}

export default ArticleCardList
