import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import DefaultButton from "../Button/DefaultButton"
import PrimaryIconButton from "../Button/PrimaryIconButton"
import { ArrowRightIcon, GetQuoteIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"

const CardDefault = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -15px 30px -15px;
  ${BreakpointUp.md`
    text-align: left;
  `}
  ${BreakpointUp.xl`
  text-align: left;
    margin:0 -30px 60px -30px;
  `}
`
const CardColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
    width: 50%;
  `}
  ${BreakpointUp.xl`
    width: 50%;
    padding:0 30px;
  `}
`

const CardDefaultTitle = styled.h3`
  color: #211f28;
  margin: 0 0 5px;
`
const CardDefaultFigure = styled.div`
  position: relative;
  padding-top: 90px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
  }
  &:before {
    width: 83px;
    height: 83px;
    border: 8px solid #f9f9f9;
    top: 32px;
    left: 35px;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 4px solid #211f28;
    top: 0;
    left: 115px;
  }
  & .gatsby-image-wrapper {
    height: 100%;
    position: relative;
    z-index: 1;
  }
`
const CardDefaultBody = styled.div`
  position: relative;
  padding: 0;
  ${BreakpointUp.md`    
    padding: 30px 30px 0 0;
  `}
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    width: 42px;
    height: 42px;
    border: 4px solid #211f28;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
    position: absolute;
    right: 60px;
    bottom: 0;
    z-index: 1;
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
`
const CardDefaultContent = styled.div`
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 20px 0 30px 0;
  ${BreakpointUp.md`    
    padding:65px 20px 65px;      
  `}
`
const CardDefaultText = styled.div`
  margin-bottom: 20px;
`
const ButtonsWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -10px;
  justify-content: center;
  button {
    margin: 0 10px;
  }
  a {
    padding: 0 10px;
  }
  ${BreakpointUp.md`
    justify-content: left;
  `}
  ${BreakpointUp.xl`     
    margin:0 -20px;
    button {
      margin: 0 20px;
    }
    a {
      padding: 0 20px;
    }
  `}
`

const CategoryCard = ({ data, intl, openQuoteModal }) => {
  const temp = data.name.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
  const titleUrl = temp.split(" ").join("-").toLowerCase()
  return (
    <CardDefault className="card">
      <CardColumn>
        <CardDefaultFigure className="card-img">
          <Img fluid={data.image.fluid} alt={data.image.description} />
        </CardDefaultFigure>
      </CardColumn>
      <CardColumn>
        <CardDefaultBody className="card-body">
          <CardDefaultContent className="card-content">
            <CardDefaultTitle>{data.name}</CardDefaultTitle>
            <CardDefaultText>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
            </CardDefaultText>
            <ButtonsWrap>
              <button
                aria-label="get a quote"
                className="btn"
                onClick={openQuoteModal}
              >
                <DefaultButton
                  icon={<GetQuoteIcon />}
                  text={intl.formatMessage({ id: "Get a quote" })}
                />
              </button>
              <Link to={`/${intl.locale}/${titleUrl}`}>
                <PrimaryIconButton
                  icon={<ArrowRightIcon />}
                  text={intl.formatMessage({ id: "Explore All" })}
                />
              </Link>
            </ButtonsWrap>
          </CardDefaultContent>
        </CardDefaultBody>
      </CardColumn>
    </CardDefault>
  )
}

export default CategoryCard
