import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import { Section, SectionTitle, SectionDescription } from "../../components/Section"
import CategoryCard from "../../components/CategoryCard"
import BreakpointUp from "../../components/Media/BreakpointUp"

const SectionHorizontal = styled.div`
  margin: 0;
  
`
const SectionItem = styled.div`
	&:nth-child(even) {
		.card {
			flex-direction: row-reverse;
			.card-img {
				padding-top:0;
				padding-right:30px;
				padding-bottom: 15px;
				${BreakpointUp.lg`					
					padding-bottom: 30px;					
				`}
				&:after{
					width: 50px;
					height: 50px;
					border:6px solid #211F28;
					top: auto;
					left:auto;
					right:15px;
					bottom:0;
					transform: inherit;
					${BreakpointUp.lg`
						width: 100px;
						height: 100px;	
						border:8px solid #211F28;				
						right:0;
					`}
				}
			}
			.card-body{
				padding: 30px 0;
				${BreakpointUp.lg`					
					padding: 30px;
				`}
				&:before {
					display:none;
				}
				&:after{ 
					width:198px;
					height:198px;
					border:20px solid #F9F9F9;
					transform: inherit;
					position:absolute;
					top:0;
					left: 0;
					bottom:auto;
					z-index:0;
					display:none;
					${BreakpointUp.lg`					
						display:block;
					`}
				}
			}
			.card-content{
				background-color:transparent;
				padding:0;
				${BreakpointUp.lg`					
					padding: 20px;
				`}
			}
		}
	}	
`

const CategorySection = ({ data, intl, openQuoteModal}) => {
	return(
		<Section pt="90px" pb="90px" xpt="60px" xpb="0" textAlign="center">
			<div className="container">				
				<SectionTitle>{data.title}</SectionTitle>
				<SectionDescription maxWidth="1120px" mb="30px">
					<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
				</SectionDescription>
				<SectionHorizontal>
					{
						data.categories.map(item => (
							<SectionItem key={v4()}>
								<CategoryCard data={item.node} intl={intl} openQuoteModal={openQuoteModal} />
							</SectionItem>	
						))
					}
				</SectionHorizontal>
			</div>
		</Section>
	)
}


export default CategorySection