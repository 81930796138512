import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import BreakpointUp from "../../components/Media/BreakpointUp"
import { Section } from "../../components/Section"
const ImageCaption = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 180px;
  padding: 0 15px;
`
const Title = styled.div`
  display: block;
  font-family: "Rajdhani", sans-serif;
  text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 42px;
  line-height: 54px;
  color: #f6f6f6;
  ${BreakpointUp.sm`
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 50%, #e9e9e9 50%, #e9e9e9 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 50%,#e9e9e9 50%,#e9e9e9 100%);
		background: linear-gradient(to bottom, #ffffff 0%,#ffffff 50%,#e9e9e9 50%,#e9e9e9 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e9e9e9',GradientType=0 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	`}
  ${BreakpointUp.md`
		font-size:54px;
		line-height:68px;		
	`}
	${BreakpointUp.lg`
		font-size:62px;
		line-height:74px;		
	`}
`
const SubTitle = styled.div`
  display: block;
  font-family: "Rajdhani", sans-serif;
  text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px;
  font-weight: 700;
  font-size: 42px;
  line-height: 54px;
  color: #f6f6f6;
  ${BreakpointUp.sm`
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 50%, #e9e9e9 50%, #e9e9e9 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 50%,#e9e9e9 50%,#e9e9e9 100%);
		background: linear-gradient(to bottom, #ffffff 0%,#ffffff 50%,#e9e9e9 50%,#e9e9e9 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e9e9e9',GradientType=0 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	`}

  ${BreakpointUp.md`
		font-size:64px;
		line-height:78px;		
	`}
	${BreakpointUp.lg`
		font-size:74px;
		line-height:88px;		
	`}
`

const DesktopHero = styled.div`
  display: none;
  ${BreakpointUp.md`	    
		display:block;
	`}
`
const MobileHero = styled.div`
  display: block;
  ${BreakpointUp.md`	    
		display:none;
	`}
`

const HeroSection = ({ data }) => {
  return (
    <Section bgColor="transparent">
      <DesktopHero>
        <Img fluid={data.image.fluid} alt={data.image.description} />
      </DesktopHero>
      <MobileHero>
        <Img
          fluid={data.mobileImage.fluid}
          alt={data.mobileImage.description}
        />
      </MobileHero>
      <ImageCaption>
        <Title>{data.title}</Title>
        <SubTitle>{data.subTitle}</SubTitle>
      </ImageCaption>
    </Section>
  )
}

export default HeroSection
