import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Section, SectionTitle } from "../../components/Section"
import DefaultButton from "../../components/Button/DefaultButton"
import { ArrowRightIcon } from "../../components/Icons"
import BreakpointUp from "../../components/Media/BreakpointUp"
import RhombusBg from "../../images/rhombus-pattern.svg"

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`
		margin-right: -25px;
		margin-left: -25px;
	`}
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
		padding-right: 25px;
		padding-left: 25px;
		flex: 0 0 50%;
		max-width: 50%;
	`}
  figure {
    text-align: right;
    a {
      display: inline-block;
      position: relative;
      bottom: 20px;
      right: 60px;
    }
    .btn {
      display: none;
      ${BreakpointUp.lg`
			display: inline-flex;
			`}
      .icon {
        svg {
          width: 14px;
          height: 18px;
          ${BreakpointUp.xl`
						width: 20px;			
					`}
        }
      }
    }
  }
`
const SectionDesc = styled.div`
  p {
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    ${BreakpointUp.md`
			align-items:center;
			margin: 0 -15px;
		`}
    li {
      position: relative;
      width: 100%;
      line-height: 26px;
      color: #1b1a21;
      font-weight: 700;
      margin-bottom: 15px;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 20px;
      padding-left: 20px;
      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background-color: #1b1a21;
        position: absolute;
        left: 0;
        top: 0;
        ${BreakpointUp.md`
					left:15px;
				`}
      }
      ${BreakpointUp.md`
				margin-bottom:30px;
				padding-right: 30px;
				padding-left: 30px;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;			
			`}
    }
  }
  .btn {
    display: inline-flex;
    ${BreakpointUp.lg`
			display: none;
		`}
  }
`
const ChooseSection = ({ data, intl }) => {
  return (
    <Section
      pt="90px"
      pb="90px"
      xpt="60px"
      xpb="60px"
      bgColor="#f9f9f9"
      bgImg={`url(${RhombusBg})`}
      bgSize="auto"
    >
      <div className="container">
        <SectionGrid>
          <SectionItem>
            <SectionTitle mb="20px" textAlign="left">
              {data.title}
            </SectionTitle>
            <figure>
              <Img fluid={data.image.fluid} alt={data.image.description} />
              <Link to={`/${intl.locale}/about-us`} className="btn-lg">
                <DefaultButton
                  text={intl.formatMessage({ id: "Learn More" })}
                  icon={<ArrowRightIcon />}
                />
              </Link>
            </figure>
          </SectionItem>
          <SectionItem>
            <SectionDesc>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
              <Link to={`/${intl.locale}/about-us`} className="btn-lg">
                <DefaultButton
                  text={intl.formatMessage({ id: "Learn More" })}
                  icon={<ArrowRightIcon />}
                />
              </Link>
            </SectionDesc>
          </SectionItem>
        </SectionGrid>
      </div>
    </Section>
  )
}

export default ChooseSection
