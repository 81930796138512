import React from "react"
import { Section, SectionTitle, SectionDescription } from "../../components/Section"
import ArticleCardList from "../../components/ArticleCardList"

const RecentArticleSection = ({ data, intl }) => {
	return(
		<Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
			<div className="container">
				<SectionTitle>{data.title}</SectionTitle>
                <SectionDescription  maxWidth="1120px" mb="50px">
					<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
				</SectionDescription>
			    <ArticleCardList intl={intl} data={data.articles}/>
			</div>
		</Section>
	)
}

export default RecentArticleSection