import React, { useState } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import QuotePopup from "../components/QuotePopup"
import HeroSection from "../sections/Home/HeroSection"
import ServiceSection from "../sections/Home/ServiceSection"
import ProductSection from "../sections/Home/ProductSection"
import RtoFinanceSection from "../sections/Home/RtoFinanceSection"
import CategorySection from "../sections/Home/CategorySection"
import ChooseCarportSection from "../sections/Home/ChooseCarportSection"
import RecentArticlesSection from "../sections/Home/RecentArticlesSection"
// import CelebratePopup from "../components/CelebratePopup"
// import HowCanWeHelp from "../components/HowCanWeHelp"

const IndexPage = ({ data, intl, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  // const [isVisibleCelebrate, setCelebrateVisible] = useState(true)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const hereData = {
    title: data.contentfulHomePage.heroTitle,
    subTitle: data.contentfulHomePage.heroSubTitle,
    image: data.contentfulHomePage.heroImage,
    mobileImage: data.contentfulHomePage.heroMobileImage,
  }
  const productSectionData = {
    title: data.contentfulHomePage.productSectionTitle,
    description: data.contentfulHomePage.productSectionDescription,
    products: data.allContentfulProduct.edges,
  }
  const categorySectionData = {
    title: data.contentfulHomePage.categorySectionTitle,
    description: data.contentfulHomePage.categorySectionDescription,
    categories: data.allContentfulProductCategory.edges,
  }
  const articleSectionData = {
    title: data.contentfulHomePage.articleSectionTitle,
    description: data.contentfulHomePage.articleSectionDescription,
    articles: data.allContentfulArticle.edges,
  }
  return (
    <Layout>
      <SEO
        title={data.contentfulHomePage.metaTitle}
        description={data.contentfulHomePage.metaDescription}
      />
      <HeroSection data={hereData} />
      <ServiceSection data={data.contentfulHomePage.deliverSection} />
      <ProductSection
        data={productSectionData}
        intl={intl}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <RtoFinanceSection
        data={data.contentfulHomePage.financingSection}
        intl={intl}
      />
      <CategorySection
        data={categorySectionData}
        intl={intl}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <ChooseCarportSection
        data={data.contentfulHomePage.chooseSection}
        intl={intl}
      />
      <RecentArticlesSection data={articleSectionData} intl={intl} />
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
      {/* <CelebratePopup
        isVisible={isVisibleCelebrate}
        onClose={() => setCelebrateVisible(false)}
        intl={intl}
      /> */}
      {/* <HowCanWeHelp location={location} /> */}
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const pageQuery = graphql`
  query IndexPageQuery($locale: String) {
    contentfulHomePage(node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      heroTitle
      heroSubTitle
      heroImage {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      heroMobileImage {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      deliverSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          description
          icon
        }
        image {
          fluid(quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      financingSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          features
          description
          icon
          url
        }
        image {
          fluid(quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
      categorySectionTitle
      categorySectionDescription {
        childMarkdownRemark {
          html
        }
      }
      chooseSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
      articleSectionTitle
      articleSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(
      filter: { featuredProduct: { eq: true }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          url
          node_locale
          productSku
          productName
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
          }
          productSubcategory {
            name
          }
          productRoof {
            name
          }
          image {
            fluid(quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          width
          length
          height
          startingPrice
          rto
          financing
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          freeDelivery
        }
      }
    }
    allContentfulProductCategory(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
        }
      }
    }
    allContentfulArticle(
      sort: { order: DESC, fields: postDate }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          postDate(formatString: "D MMM YYYY")
          category {
            name
          }
          image {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          content {
            childMarkdownRemark {
              excerpt(pruneLength: 270)
            }
          }
        }
      }
    }
  }
`
