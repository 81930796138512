import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`		
	background: #6a6a6a;
	background: -moz-linear-gradient(top,  #6a6a6a 0%, #6a6a6a 50%, #211f28 50%, #211f28 100%);
	background: -webkit-linear-gradient(top,  #6a6a6a 0%,#6a6a6a 50%,#211f28 50%,#211f28 100%);
	background: linear-gradient(to bottom,  #6a6a6a 0%,#6a6a6a 50%,#211f28 50%,#211f28 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a6a6a', endColorstr='#211f28',GradientType=0 );
	border: 1px solid #fff;
	color:#fff;	
	box-shadow:0 3px 6px rgba(0,0,0,0.3);
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background: #211f28;
		background: -moz-linear-gradient(top,  #211f28 0%, #211f28 50%, #6a6a6a 50%, #6a6a6a 100%);
		background: -webkit-linear-gradient(top,  #211f28 0%,#211f28 50%,#6a6a6a 50%,#6a6a6a 100%);
		background: linear-gradient(to bottom,  #211f28 0%,#211f28 50%,#6a6a6a 50%,#6a6a6a 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#211f28', endColorstr='#6a6a6a',GradientType=0 );
	}
`

const PrimaryButton = (props) => {
	const { icon, text } = props;
	return(
		<PrimaryBtn className='btn'>
			<span className='icon'>{icon}</span><span className='text'>{text}</span>
		</PrimaryBtn>
	)
}

export default PrimaryButton