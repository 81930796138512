import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Section, SectionTitle, SectionDescription } from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"

const ProductSection = ({ data, intl, openQuoteModal }) => {
	return(
		<Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff" bdrBottom="#dbdbdb">
			<div className="container">
				<SectionTitle>{data.title}</SectionTitle>
                <SectionDescription  maxWidth="1120px" mb="60px">
				  	<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
				</SectionDescription>
			    <ProductCardList intl={intl} data={data.products} openQuoteModal={openQuoteModal} />
			</div>
		</Section>
	)
}

export default injectIntl(ProductSection)