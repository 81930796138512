import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import { Section, SectionPageTitle, SectionDescription } from "../../components/Section"
import BreakpointUp from "../../components/Media/BreakpointUp"

const ContentGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items:center;		
	margin-right: -15px;
	margin-left: -15px;
	text-align:center;
	${BreakpointUp.lg`	
		text-align:left;
	`}
`
const ContentLeft = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;	
	${BreakpointUp.lg`
		flex: 0 0 50%;
		max-width: 50%;
	`}
`
const ContentRight = styled.div`
	width:100%;
	padding:0 15px;
	${BreakpointUp.lg`				
		flex: 0 0 50%;
		max-width: 50%;
	`}
`
const ServiceGrid = styled.div`
	display: flex;
	flex-wrap: wrap;	
	margin:0 -15px;
	justify-content: center;
	position:relative;
	${BreakpointUp.lg`
		align-items: center;
	`}
`
const ServiceItem = styled.div`
	width: 100%;
	padding:0 15px;
	position: relative;
	${BreakpointUp.md`
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	`}
	${BreakpointUp.lg`
		flex: 0 0 50%;
		max-width: 50%;
		margin-left: 50%;
		&:first-child{
			position:absolute;
			top:50%;
			left:0;
			transform:translateY(-50%);
			margin-left: 0;	
		}
	`}
	${BreakpointUp.xl`
		&:first-child{
			&:before{
				content:'';
				width:198px;
				height:198px;
				position:absolute;
				top:50%;
				left:0;
				transform:translateY(-100%);
				background-color:#fff;
				border:20px solid #F9F9F9;	
			}	
		}
	`}
`

const Card = styled.div`
	background-color:#fff;
	border:1px solid #E3E3E3;	
    box-shadow: 0px 3px 20px rgba(0,0,0,0.1);
    margin-bottom:25px;
	position:relative;
	${BreakpointUp.md`
		height:100%;
	`}
	${BreakpointUp.md`
		max-width:280px;
		width:100%;
		margin-left: auto;
	`}

    &:after{
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        background-color:rgba(0, 0, 0, 0.05);
        right:-8px;
        bottom:-8px
    }
`
const CardIcon = styled.div`
	margin-bottom:0;
`
const CardBody = styled.div`
    margin:0;
    padding:30px 15px;
    text-align:center;    
    background-color:#fff;
    z-index: 1;
	position: relative;
	${BreakpointUp.md`
		height:100%;
	`}
`
const CardTitle = styled.div`
    color:#211F28;
    font-size:26px;
    line-height:38px;
    font-family: 'Rajdhani', sans-serif;
    font-weight:700;
`
const CardText = styled.p`
	margin:0;
`
const BackgroundImage = styled.div`
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left:0;
	bottom:0;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	${BreakpointUp.xl`
		width: 50%;
	`}
`

const ServiceSection = ({ data }) => {    
	return(
		<Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff" bdrBottom="#dbdbdb">
			<BackgroundImage><Img fluid={data.image.fluid} alt="usp"/></BackgroundImage>
			<div className="container">
				<ContentGrid>
                    <ContentLeft>
						<SectionPageTitle textAlign="left" lgTextAlign="center">
							{data.title}
						</SectionPageTitle>                
						<SectionDescription textAlign="left" lgTextAlign="center">
							<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
						</SectionDescription>		
					</ContentLeft>	

					<ContentRight>
                        <ServiceGrid>
							{
								data.features.map((feature) => (
									<ServiceItem key={v4()}>
										<Card className="card">
											<CardBody className="card-body">
												<CardIcon><img src={feature.icon} alt={feature.title} /></CardIcon>
												<CardTitle>{feature.title}</CardTitle>
												<CardText>{feature.description}</CardText>
											</CardBody>
										</Card>
									</ServiceItem>
								))
							}
                        </ServiceGrid>										
					</ContentRight>
				</ContentGrid>				
			</div>
		</Section>
	)
}

export default ServiceSection
