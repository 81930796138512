import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid"
import DefaultButton from "../../components/Button/DefaultButton"
import {
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/Section"
import { ArrowRightIcon } from "../../components/Icons"
import BreakpointUp from "../../components/Media/BreakpointUp"

const FinanceSection = styled.div`
  position: relative;
  padding: 30px 0 60px 0;
  position: relative;
  background-color: #0f0f11;
  ${BreakpointUp.xl`
		padding:50px 0 90px 0;
	`}
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  z-index: 1;
  margin: 0 -15px;
  ${BreakpointUp.lg`
		margin:0 -30px;		
	`}
`
const SectionItem = styled.div`
  width: 100%;
  position: relative;
  text-align: left;
  padding: 0 15px;
  ${BreakpointUp.lg`
		padding:0 30px;	
		flex: 0 0 50%;
		max-width: 50%;
	`}
`

const SectionDesc = styled.div`
  max-width: 600px;
  width: 100%;
  color: #fff;
  img {
    margin-bottom: 20px;
    max-width: 100%;
  }
  h3 {
    color: #fff;
    ${BreakpointUp.lg`
			font-size:40px;
			line-height:48px;
		`}
  }
  p,
  ul {
    color: #cfcfcf;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0 -15px;
    li {
      position: relative;
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      margin-bottom: 50px;
      line-height: 24px;
      flex: 0 0 50%;
      max-width: 50%;
      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background-color: #cfcfcf;
        position: absolute;
        left: 15px;
        top: 0;
      }
    }
  }
  .btn {
    .icon {
      svg {
        height: 18px;
        width: 14px;
        ${BreakpointUp.xl`					
					height: 18px;
					width: 20px;
				`}
      }
    }
  }
`

const BackgroundImage = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  &:before {
    background-color: rgba(106, 106, 106, 0.15);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    ${BreakpointUp.lg`
			width:50%;
			height:100%;
			clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
		`}
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
`

const RtoFinanceSection = ({ data, intl }) => {
  return (
    <Section
      pt="90px"
      pb="0"
      xpt="60px"
      xpb="0"
      bgColor="#fff"
      bdrBottom="#dbdbdb"
    >
      <div className="container">
        <SectionTitle>{data.title}</SectionTitle>
        <SectionDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </SectionDescription>
      </div>
      <FinanceSection>
        <BackgroundImage>
          <Img fluid={data.image.fluid} alt="financing" />
        </BackgroundImage>
        <div className="container">
          <SectionGrid>
            {data.features.map(feature => (
              <SectionItem key={v4()}>
                <SectionDesc>
                  <img src={feature.icon} alt={feature.title} />
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                  <ul>
                    {feature.features.map((item, i) => (
                      <li key={i}>{item.content}</li>
                    ))}
                  </ul>
                  <Link to={`/${intl.locale}${feature.url}`} className="btn-lg">
                    <DefaultButton
                      text={intl.formatMessage({ id: "Apply Now" })}
                      icon={<ArrowRightIcon />}
                    />
                  </Link>
                </SectionDesc>
              </SectionItem>
            ))}
          </SectionGrid>
        </div>
      </FinanceSection>
    </Section>
  )
}

export default RtoFinanceSection
